import React, { useState, useReducer, useContext, useEffect } from "react";
import "./StartTest.css";
import { useHistory, useParams } from "react-router-dom";
import { apiCall, setTokenInHeaders, setApiToken } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";

function StartTest(props) {
  const history = useHistory();
  const { set_user_data, user_details } = useContext(UserCredsContext);
  const [toast, setToast] = useState({ msg: "", type: "" });
  const { param1, param2 } = useParams();
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    apiCall
      .post("/partners/tests/verifyid", {
        partner_login_id: param1,
        gre_code: param2,
      })
      .then((response) => {
        if (response.status === 200) {
          setToast({ msg: "success!", type: "success" });
          const { token } = response.data;
          // console.log(token)

          set_user_data(token);
          localStorage.setItem("network_access_token", token);

          setTokenInHeaders(token);
          setApiToken(token);

          setShowDiv(true);
        } else {
          throw new Error(response.data.data || "Something went wrong!");
        }
      })
      .catch((error) => {
        console.error(error);
        setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
      });
  };

  const handleClick = () => {
    history.push("/startExam");
  };

  return (
    <div>
      <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
        {toast.msg}
      </div>

      <div className="main">
        <h5>Instructions</h5>

        <div className="tableDiv">
          <table>
            <thead>
              <tr>
                <th>Measure</th>
                <th>Question</th>
                <th>Time Limit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Analytical Writing (One section)</td>
                <td>1 Essay</td>
                <td>30 minutes</td>
              </tr>
              <tr>
                <td>Verbal Reasoning (Two sections)</td>
                <td>
                  <p>Section 1: 12 questions</p>
                  <p>Section 2: 15 questions</p>
                </td>
                <td>
                  <p>Section 1: 18 minutes</p>
                  <p>Section 2: 23 minutes</p>
                </td>
              </tr>
              <tr>
                <td>Quantitative Reasoning (Two sections)</td>
                <td>
                  <p>Section 1: 12 questions</p>
                  <p>Section 2: 15 questions</p>
                </td>
                <td>
                  <p>Section 1: 21 minutes</p>
                  <p>Section 2: 26 minutes</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {showDiv && (
          <div className="btnDiv">
            <button onClick={handleClick}>Start Test</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default StartTest;
