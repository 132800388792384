import React, { useContext, useState } from "react";
import "./navbar.css";
import { Link, withRouter } from "react-router-dom";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import impLogo from "../../Assets/footer/logo.png";

const Navbar = (props) => {
  const { _token } = useContext(UserCredsContext);
  const { logout } = useContext(UserCredsContext);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  // console.log(props.location.pathname,_token,'pathname')
  return (
    <>
      <header id="header" class="header fixed-top">
        <div class="container-fluid px-0">
          <nav className="navbar">
            <div className="nav-container px-lg-5">
              <Link class="nav-link scrollto" to="">
                <a class="logo d-flex align-items-center">
                  {/* <img src={impLogo} alt="" /> */}
                  <h1>testprep.<span>live</span></h1>
                </a>
              </Link>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default withRouter(Navbar);
